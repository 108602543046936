var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"img-wrapper",style:({
      height: _vm.height + 'px',
      width: _vm.width + 'px',
    })},[_c('div',{staticClass:"inside",style:({
        height: _vm.height - 0.1 + 'px',
        width: _vm.width - 0.1 + 'px',
      })},[(_vm.background)?_c('img',{staticClass:"input-wrapper",attrs:{"src":_vm.background}}):_c('b-icon',{staticClass:"default-img",style:({
          height: _vm.iconHeight + 'px',
          width: _vm.iconWidth + 'px',
        }),attrs:{"icon":"person-fill"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }